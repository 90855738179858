import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as indexEPln40kqpPMeta } from "/opt/build/repo/pages/hub/account/index.vue?macro=true";
import { default as editBwc9ORKOs9Meta } from "/opt/build/repo/pages/hub/clients/[client_id]/edit.vue?macro=true";
import { default as indexOAqccFdoOQMeta } from "/opt/build/repo/pages/hub/clients/index.vue?macro=true";
import { default as indexgEAgs95LC2Meta } from "/opt/build/repo/pages/hub/index.vue?macro=true";
import { default as editd2zcfoJGf6Meta } from "/opt/build/repo/pages/hub/sessions/[session_id]/edit.vue?macro=true";
import { default as documentsd5SRzo1rvjMeta } from "/opt/build/repo/pages/hub/sessions/create/[session_id]/documents.vue?macro=true";
import { default as participantsVq9fwzVhKkMeta } from "/opt/build/repo/pages/hub/sessions/create/[session_id]/participants.vue?macro=true";
import { default as indexZJBTrVHdtTMeta } from "/opt/build/repo/pages/hub/sessions/create/index.vue?macro=true";
import { default as indexUMANlCR4XrMeta } from "/opt/build/repo/pages/hub/sessions/index.vue?macro=true";
import { default as hub9drsthsLMAMeta } from "/opt/build/repo/pages/hub.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexzz3rfjodlHMeta } from "/opt/build/repo/pages/invite/index.vue?macro=true";
import { default as oauthzw5dRG2bGEMeta } from "/opt/build/repo/pages/invite/oauth.vue?macro=true";
import { default as select_45oauthghZx09BrT1Meta } from "/opt/build/repo/pages/invite/select-oauth.vue?macro=true";
import { default as inviteCDBEKniAklMeta } from "/opt/build/repo/pages/invite.vue?macro=true";
import { default as index8hy9a9MmevMeta } from "/opt/build/repo/pages/join/[id]/index.vue?macro=true";
import { default as index9WruYoq02MMeta } from "/opt/build/repo/pages/login/index.vue?macro=true";
import { default as oauth0RwTqRL9CGMeta } from "/opt/build/repo/pages/login/oauth.vue?macro=true";
import { default as password_45resetcU3cl9uR7OMeta } from "/opt/build/repo/pages/login/password-reset.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as account5fvwcD4WjjMeta } from "/opt/build/repo/pages/onboarding/account.vue?macro=true";
import { default as agreementcXCZWdpfbBMeta } from "/opt/build/repo/pages/onboarding/agreement.vue?macro=true";
import { default as contactsWg0Bj8oe02Meta } from "/opt/build/repo/pages/onboarding/contacts.vue?macro=true";
import { default as customizeeZgZEWs6AjMeta } from "/opt/build/repo/pages/onboarding/customize.vue?macro=true";
import { default as indexYw9kJj45fLMeta } from "/opt/build/repo/pages/onboarding/index.vue?macro=true";
import { default as paymentRAuWnR2hq8Meta } from "/opt/build/repo/pages/onboarding/payment.vue?macro=true";
import { default as typelZ2u0XpEY6Meta } from "/opt/build/repo/pages/onboarding/type.vue?macro=true";
import { default as verifyw9rcssSTQJMeta } from "/opt/build/repo/pages/onboarding/verify.vue?macro=true";
import { default as onboardingRPxvyUqUaXMeta } from "/opt/build/repo/pages/onboarding.vue?macro=true";
import { default as indexJuOSuqt7LSMeta } from "/opt/build/repo/pages/operations/index.vue?macro=true";
import { default as password_45resethCeG5Oiil0Meta } from "/opt/build/repo/pages/password-reset.vue?macro=true";
import { default as privacy_45policyQXTlhFuZ55Meta } from "/opt/build/repo/pages/privacy-policy.vue?macro=true";
import { default as editvuwLf13hSVMeta } from "/opt/build/repo/pages/records/alabama/[record_id]/edit.vue?macro=true";
import { default as create3eDLuTi6pZMeta } from "/opt/build/repo/pages/records/alabama/create.vue?macro=true";
import { default as index1pd5d9tH9GMeta } from "/opt/build/repo/pages/records/alabama/index.vue?macro=true";
import { default as editI96roreSWdMeta } from "/opt/build/repo/pages/records/florida/[record_id]/edit.vue?macro=true";
import { default as createLu2EDdyDJuMeta } from "/opt/build/repo/pages/records/florida/create.vue?macro=true";
import { default as indexYnUaY46es9Meta } from "/opt/build/repo/pages/records/florida/index.vue?macro=true";
import { default as editi4AJ7Yzty9Meta } from "/opt/build/repo/pages/records/georgia/[record_id]/edit.vue?macro=true";
import { default as create6WpUiTwP1SMeta } from "/opt/build/repo/pages/records/georgia/create.vue?macro=true";
import { default as indexrthmXN2hhyMeta } from "/opt/build/repo/pages/records/georgia/index.vue?macro=true";
import { default as editmuTIfY2E6yMeta } from "/opt/build/repo/pages/records/iIllinois/[record_id]/edit.vue?macro=true";
import { default as create4WHHSNFLUmMeta } from "/opt/build/repo/pages/records/iIllinois/create.vue?macro=true";
import { default as indexLPdTYsg3qGMeta } from "/opt/build/repo/pages/records/iIllinois/index.vue?macro=true";
import { default as editDiNQZ3yUR0Meta } from "/opt/build/repo/pages/records/mississippi/[record_id]/edit.vue?macro=true";
import { default as createdybCH2sDtoMeta } from "/opt/build/repo/pages/records/mississippi/create.vue?macro=true";
import { default as index4Vu5PwaTpyMeta } from "/opt/build/repo/pages/records/mississippi/index.vue?macro=true";
import { default as editFX5hBdLyHIMeta } from "/opt/build/repo/pages/records/new-jersey/[record_id]/edit.vue?macro=true";
import { default as createhMTTRBqCGZMeta } from "/opt/build/repo/pages/records/new-jersey/create.vue?macro=true";
import { default as index1rZDvj2nvQMeta } from "/opt/build/repo/pages/records/new-jersey/index.vue?macro=true";
import { default as edit6ES7MotAVFMeta } from "/opt/build/repo/pages/records/new-york/[record_id]/edit.vue?macro=true";
import { default as createaaFZSVXqMlMeta } from "/opt/build/repo/pages/records/new-york/create.vue?macro=true";
import { default as indexDFs6r5W82nMeta } from "/opt/build/repo/pages/records/new-york/index.vue?macro=true";
import { default as editiTIB2B4QVPMeta } from "/opt/build/repo/pages/records/tennessee/[record_id]/edit.vue?macro=true";
import { default as createC4dB8mHEtIMeta } from "/opt/build/repo/pages/records/tennessee/create.vue?macro=true";
import { default as indexMkVPWH8KpYMeta } from "/opt/build/repo/pages/records/tennessee/index.vue?macro=true";
import { default as editXxwylp1zBGMeta } from "/opt/build/repo/pages/records/texas/[record_id]/edit.vue?macro=true";
import { default as createHRzXVcVU25Meta } from "/opt/build/repo/pages/records/texas/create.vue?macro=true";
import { default as indexoxFFKz9iyhMeta } from "/opt/build/repo/pages/records/texas/index.vue?macro=true";
import { default as recordsC0OoK9hOGIMeta } from "/opt/build/repo/pages/records.vue?macro=true";
import { default as indexllN7HpClHUMeta } from "/opt/build/repo/pages/register/index.vue?macro=true";
import { default as oauthm8Jle9RACKMeta } from "/opt/build/repo/pages/register/oauth.vue?macro=true";
import { default as select_45oauth81AKiTF2WfMeta } from "/opt/build/repo/pages/register/select-oauth.vue?macro=true";
import { default as registeryZbpxRGV6SMeta } from "/opt/build/repo/pages/register.vue?macro=true";
import { default as detailsUWiJAmOr7UMeta } from "/opt/build/repo/pages/sessions/[id]/associate/[user_id]/details.vue?macro=true";
import { default as indexzbdcsNzKRLMeta } from "/opt/build/repo/pages/sessions/[id]/associate/[user_id]/index.vue?macro=true";
import { default as roomVxwAHPbxgRMeta } from "/opt/build/repo/pages/sessions/[id]/associate/[user_id]/room.vue?macro=true";
import { default as waiting_45room6PiHll6YqWMeta } from "/opt/build/repo/pages/sessions/[id]/associate/[user_id]/waiting-room.vue?macro=true";
import { default as authFQRwju86WIMeta } from "/opt/build/repo/pages/sessions/[id]/client/[user_id]/auth.vue?macro=true";
import { default as denied1z48I7upTiMeta } from "/opt/build/repo/pages/sessions/[id]/client/[user_id]/denied.vue?macro=true";
import { default as detailsLtOVUaLf8hMeta } from "/opt/build/repo/pages/sessions/[id]/client/[user_id]/details.vue?macro=true";
import { default as documentsofTwpWmAalMeta } from "/opt/build/repo/pages/sessions/[id]/client/[user_id]/documents.vue?macro=true";
import { default as downloaddgsCwzc61UMeta } from "/opt/build/repo/pages/sessions/[id]/client/[user_id]/download.vue?macro=true";
import { default as indexA2lirZ54mEMeta } from "/opt/build/repo/pages/sessions/[id]/client/[user_id]/index.vue?macro=true";
import { default as kbafDK2rnu9RUMeta } from "/opt/build/repo/pages/sessions/[id]/client/[user_id]/kba.vue?macro=true";
import { default as previewmNROKE96X3Meta } from "/opt/build/repo/pages/sessions/[id]/client/[user_id]/preview.vue?macro=true";
import { default as questionsmBx72aRJLJMeta } from "/opt/build/repo/pages/sessions/[id]/client/[user_id]/questions.vue?macro=true";
import { default as roomxmx5sS5ZhPMeta } from "/opt/build/repo/pages/sessions/[id]/client/[user_id]/room.vue?macro=true";
import { default as switchvpulPnbiBMMeta } from "/opt/build/repo/pages/sessions/[id]/client/[user_id]/switch.vue?macro=true";
import { default as waiting_45roomjIpR5DWoqgMeta } from "/opt/build/repo/pages/sessions/[id]/client/[user_id]/waiting-room.vue?macro=true";
import { default as editLClYUMianTMeta } from "/opt/build/repo/pages/sessions/[id]/documents/edit.vue?macro=true";
import { default as index1Bv63MA5pjMeta } from "/opt/build/repo/pages/sessions/[id]/host/[user_id]/index.vue?macro=true";
import { default as roomLR3Ui6umMDMeta } from "/opt/build/repo/pages/sessions/[id]/host/[user_id]/room.vue?macro=true";
import { default as indexTLPotumsJJMeta } from "/opt/build/repo/pages/sessions/[id]/team/[user_id]/index.vue?macro=true";
import { default as room56izYzaiKeMeta } from "/opt/build/repo/pages/sessions/[id]/team/[user_id]/room.vue?macro=true";
import { default as waiting_45roomQErKsWyVLuMeta } from "/opt/build/repo/pages/sessions/[id]/team/[user_id]/waiting-room.vue?macro=true";
import { default as autheaTmcoz9brMeta } from "/opt/build/repo/pages/sessions/[id]/witness/[user_id]/auth.vue?macro=true";
import { default as deniedGPduTJzmCLMeta } from "/opt/build/repo/pages/sessions/[id]/witness/[user_id]/denied.vue?macro=true";
import { default as detailsNdxhZhnARpMeta } from "/opt/build/repo/pages/sessions/[id]/witness/[user_id]/details.vue?macro=true";
import { default as indexc1QXq82ouvMeta } from "/opt/build/repo/pages/sessions/[id]/witness/[user_id]/index.vue?macro=true";
import { default as kbaxaveO91oHVMeta } from "/opt/build/repo/pages/sessions/[id]/witness/[user_id]/kba.vue?macro=true";
import { default as roomcSdLkvx0kBMeta } from "/opt/build/repo/pages/sessions/[id]/witness/[user_id]/room.vue?macro=true";
import { default as waiting_45roomzdt5AVcVcqMeta } from "/opt/build/repo/pages/sessions/[id]/witness/[user_id]/waiting-room.vue?macro=true";
import { default as indexrpMW0DQkYiMeta } from "/opt/build/repo/pages/sessions/index.vue?macro=true";
import { default as sessionsN4huw98JZDMeta } from "/opt/build/repo/pages/sessions.vue?macro=true";
import { default as switch4PFLqDQchJMeta } from "/opt/build/repo/pages/switch.vue?macro=true";
import { default as closedjjoznYf5MWMeta } from "/opt/build/repo/pages/system/closed.vue?macro=true";
import { default as maintenanceikRuxCc89GMeta } from "/opt/build/repo/pages/system/maintenance.vue?macro=true";
import { default as not_45founduAKyhnbCbOMeta } from "/opt/build/repo/pages/system/not-found.vue?macro=true";
import { default as pendingONJjWPORT8Meta } from "/opt/build/repo/pages/system/pending.vue?macro=true";
import { default as suspendedTnN1j4e4NIMeta } from "/opt/build/repo/pages/system/suspended.vue?macro=true";
import { default as terms_45of_45serviceIo0hlUNnc4Meta } from "/opt/build/repo/pages/terms-of-service.vue?macro=true";
import { default as editYxXwgfJjtNMeta } from "/opt/build/repo/pages/user/edit.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93EhWyQvnGPmMeta?.name ?? "slug",
    path: _91_46_46_46slug_93EhWyQvnGPmMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    alias: _91_46_46_46slug_93EhWyQvnGPmMeta?.alias || [],
    redirect: _91_46_46_46slug_93EhWyQvnGPmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: hub9drsthsLMAMeta?.name ?? undefined,
    path: hub9drsthsLMAMeta?.path ?? "/hub",
    meta: hub9drsthsLMAMeta || {},
    alias: hub9drsthsLMAMeta?.alias || [],
    redirect: hub9drsthsLMAMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hub.vue").then(m => m.default || m),
    children: [
  {
    name: indexEPln40kqpPMeta?.name ?? "hub-account",
    path: indexEPln40kqpPMeta?.path ?? "account",
    meta: indexEPln40kqpPMeta || {},
    alias: indexEPln40kqpPMeta?.alias || [],
    redirect: indexEPln40kqpPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hub/account/index.vue").then(m => m.default || m)
  },
  {
    name: editBwc9ORKOs9Meta?.name ?? "hub-clients-client_id-edit",
    path: editBwc9ORKOs9Meta?.path ?? "clients/:client_id()/edit",
    meta: editBwc9ORKOs9Meta || {},
    alias: editBwc9ORKOs9Meta?.alias || [],
    redirect: editBwc9ORKOs9Meta?.redirect,
    component: () => import("/opt/build/repo/pages/hub/clients/[client_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexOAqccFdoOQMeta?.name ?? "hub-clients",
    path: indexOAqccFdoOQMeta?.path ?? "clients",
    meta: indexOAqccFdoOQMeta || {},
    alias: indexOAqccFdoOQMeta?.alias || [],
    redirect: indexOAqccFdoOQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hub/clients/index.vue").then(m => m.default || m)
  },
  {
    name: indexgEAgs95LC2Meta?.name ?? "hub",
    path: indexgEAgs95LC2Meta?.path ?? "",
    meta: indexgEAgs95LC2Meta || {},
    alias: indexgEAgs95LC2Meta?.alias || [],
    redirect: indexgEAgs95LC2Meta?.redirect,
    component: () => import("/opt/build/repo/pages/hub/index.vue").then(m => m.default || m)
  },
  {
    name: editd2zcfoJGf6Meta?.name ?? "hub-sessions-session_id-edit",
    path: editd2zcfoJGf6Meta?.path ?? "sessions/:session_id()/edit",
    meta: editd2zcfoJGf6Meta || {},
    alias: editd2zcfoJGf6Meta?.alias || [],
    redirect: editd2zcfoJGf6Meta?.redirect,
    component: () => import("/opt/build/repo/pages/hub/sessions/[session_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: documentsd5SRzo1rvjMeta?.name ?? "hub-sessions-create-session_id-documents",
    path: documentsd5SRzo1rvjMeta?.path ?? "sessions/create/:session_id()/documents",
    meta: documentsd5SRzo1rvjMeta || {},
    alias: documentsd5SRzo1rvjMeta?.alias || [],
    redirect: documentsd5SRzo1rvjMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hub/sessions/create/[session_id]/documents.vue").then(m => m.default || m)
  },
  {
    name: participantsVq9fwzVhKkMeta?.name ?? "hub-sessions-create-session_id-participants",
    path: participantsVq9fwzVhKkMeta?.path ?? "sessions/create/:session_id()/participants",
    meta: participantsVq9fwzVhKkMeta || {},
    alias: participantsVq9fwzVhKkMeta?.alias || [],
    redirect: participantsVq9fwzVhKkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hub/sessions/create/[session_id]/participants.vue").then(m => m.default || m)
  },
  {
    name: indexZJBTrVHdtTMeta?.name ?? "hub-sessions-create",
    path: indexZJBTrVHdtTMeta?.path ?? "sessions/create",
    meta: indexZJBTrVHdtTMeta || {},
    alias: indexZJBTrVHdtTMeta?.alias || [],
    redirect: indexZJBTrVHdtTMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hub/sessions/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexUMANlCR4XrMeta?.name ?? "hub-sessions",
    path: indexUMANlCR4XrMeta?.path ?? "sessions",
    meta: indexUMANlCR4XrMeta || {},
    alias: indexUMANlCR4XrMeta?.alias || [],
    redirect: indexUMANlCR4XrMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hub/sessions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inviteCDBEKniAklMeta?.name ?? undefined,
    path: inviteCDBEKniAklMeta?.path ?? "/invite",
    meta: inviteCDBEKniAklMeta || {},
    alias: inviteCDBEKniAklMeta?.alias || [],
    redirect: inviteCDBEKniAklMeta?.redirect,
    component: () => import("/opt/build/repo/pages/invite.vue").then(m => m.default || m),
    children: [
  {
    name: indexzz3rfjodlHMeta?.name ?? "invite",
    path: indexzz3rfjodlHMeta?.path ?? "",
    meta: indexzz3rfjodlHMeta || {},
    alias: indexzz3rfjodlHMeta?.alias || [],
    redirect: indexzz3rfjodlHMeta?.redirect,
    component: () => import("/opt/build/repo/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: oauthzw5dRG2bGEMeta?.name ?? "invite-oauth",
    path: oauthzw5dRG2bGEMeta?.path ?? "oauth",
    meta: oauthzw5dRG2bGEMeta || {},
    alias: oauthzw5dRG2bGEMeta?.alias || [],
    redirect: oauthzw5dRG2bGEMeta?.redirect,
    component: () => import("/opt/build/repo/pages/invite/oauth.vue").then(m => m.default || m)
  },
  {
    name: select_45oauthghZx09BrT1Meta?.name ?? "invite-select-oauth",
    path: select_45oauthghZx09BrT1Meta?.path ?? "select-oauth",
    meta: select_45oauthghZx09BrT1Meta || {},
    alias: select_45oauthghZx09BrT1Meta?.alias || [],
    redirect: select_45oauthghZx09BrT1Meta?.redirect,
    component: () => import("/opt/build/repo/pages/invite/select-oauth.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index8hy9a9MmevMeta?.name ?? "join-id",
    path: index8hy9a9MmevMeta?.path ?? "/join/:id()",
    meta: index8hy9a9MmevMeta || {},
    alias: index8hy9a9MmevMeta?.alias || [],
    redirect: index8hy9a9MmevMeta?.redirect,
    component: () => import("/opt/build/repo/pages/join/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? undefined,
    path: loginBXD9sq3T3sMeta?.path ?? "/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m),
    children: [
  {
    name: index9WruYoq02MMeta?.name ?? "login",
    path: index9WruYoq02MMeta?.path ?? "",
    meta: index9WruYoq02MMeta || {},
    alias: index9WruYoq02MMeta?.alias || [],
    redirect: index9WruYoq02MMeta?.redirect,
    component: () => import("/opt/build/repo/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: oauth0RwTqRL9CGMeta?.name ?? "login-oauth",
    path: oauth0RwTqRL9CGMeta?.path ?? "oauth",
    meta: oauth0RwTqRL9CGMeta || {},
    alias: oauth0RwTqRL9CGMeta?.alias || [],
    redirect: oauth0RwTqRL9CGMeta?.redirect,
    component: () => import("/opt/build/repo/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: password_45resetcU3cl9uR7OMeta?.name ?? "login-password-reset",
    path: password_45resetcU3cl9uR7OMeta?.path ?? "password-reset",
    meta: password_45resetcU3cl9uR7OMeta || {},
    alias: password_45resetcU3cl9uR7OMeta?.alias || [],
    redirect: password_45resetcU3cl9uR7OMeta?.redirect,
    component: () => import("/opt/build/repo/pages/login/password-reset.vue").then(m => m.default || m)
  }
]
  },
  {
    name: onboardingRPxvyUqUaXMeta?.name ?? undefined,
    path: onboardingRPxvyUqUaXMeta?.path ?? "/onboarding",
    meta: onboardingRPxvyUqUaXMeta || {},
    alias: onboardingRPxvyUqUaXMeta?.alias || [],
    redirect: onboardingRPxvyUqUaXMeta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding.vue").then(m => m.default || m),
    children: [
  {
    name: account5fvwcD4WjjMeta?.name ?? "onboarding-account",
    path: account5fvwcD4WjjMeta?.path ?? "account",
    meta: account5fvwcD4WjjMeta || {},
    alias: account5fvwcD4WjjMeta?.alias || [],
    redirect: account5fvwcD4WjjMeta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/account.vue").then(m => m.default || m)
  },
  {
    name: agreementcXCZWdpfbBMeta?.name ?? "onboarding-agreement",
    path: agreementcXCZWdpfbBMeta?.path ?? "agreement",
    meta: agreementcXCZWdpfbBMeta || {},
    alias: agreementcXCZWdpfbBMeta?.alias || [],
    redirect: agreementcXCZWdpfbBMeta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/agreement.vue").then(m => m.default || m)
  },
  {
    name: contactsWg0Bj8oe02Meta?.name ?? "onboarding-contacts",
    path: contactsWg0Bj8oe02Meta?.path ?? "contacts",
    meta: contactsWg0Bj8oe02Meta || {},
    alias: contactsWg0Bj8oe02Meta?.alias || [],
    redirect: contactsWg0Bj8oe02Meta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/contacts.vue").then(m => m.default || m)
  },
  {
    name: customizeeZgZEWs6AjMeta?.name ?? "onboarding-customize",
    path: customizeeZgZEWs6AjMeta?.path ?? "customize",
    meta: customizeeZgZEWs6AjMeta || {},
    alias: customizeeZgZEWs6AjMeta?.alias || [],
    redirect: customizeeZgZEWs6AjMeta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/customize.vue").then(m => m.default || m)
  },
  {
    name: indexYw9kJj45fLMeta?.name ?? "onboarding",
    path: indexYw9kJj45fLMeta?.path ?? "",
    meta: indexYw9kJj45fLMeta || {},
    alias: indexYw9kJj45fLMeta?.alias || [],
    redirect: indexYw9kJj45fLMeta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: paymentRAuWnR2hq8Meta?.name ?? "onboarding-payment",
    path: paymentRAuWnR2hq8Meta?.path ?? "payment",
    meta: paymentRAuWnR2hq8Meta || {},
    alias: paymentRAuWnR2hq8Meta?.alias || [],
    redirect: paymentRAuWnR2hq8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/payment.vue").then(m => m.default || m)
  },
  {
    name: typelZ2u0XpEY6Meta?.name ?? "onboarding-type",
    path: typelZ2u0XpEY6Meta?.path ?? "type",
    meta: typelZ2u0XpEY6Meta || {},
    alias: typelZ2u0XpEY6Meta?.alias || [],
    redirect: typelZ2u0XpEY6Meta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/type.vue").then(m => m.default || m)
  },
  {
    name: verifyw9rcssSTQJMeta?.name ?? "onboarding-verify",
    path: verifyw9rcssSTQJMeta?.path ?? "verify",
    meta: verifyw9rcssSTQJMeta || {},
    alias: verifyw9rcssSTQJMeta?.alias || [],
    redirect: verifyw9rcssSTQJMeta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/verify.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexJuOSuqt7LSMeta?.name ?? "operations",
    path: indexJuOSuqt7LSMeta?.path ?? "/operations",
    meta: indexJuOSuqt7LSMeta || {},
    alias: indexJuOSuqt7LSMeta?.alias || [],
    redirect: indexJuOSuqt7LSMeta?.redirect,
    component: () => import("/opt/build/repo/pages/operations/index.vue").then(m => m.default || m)
  },
  {
    name: password_45resethCeG5Oiil0Meta?.name ?? "password-reset",
    path: password_45resethCeG5Oiil0Meta?.path ?? "/password-reset",
    meta: password_45resethCeG5Oiil0Meta || {},
    alias: password_45resethCeG5Oiil0Meta?.alias || [],
    redirect: password_45resethCeG5Oiil0Meta?.redirect,
    component: () => import("/opt/build/repo/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQXTlhFuZ55Meta?.name ?? "privacy-policy",
    path: privacy_45policyQXTlhFuZ55Meta?.path ?? "/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    alias: privacy_45policyQXTlhFuZ55Meta?.alias || [],
    redirect: privacy_45policyQXTlhFuZ55Meta?.redirect,
    component: () => import("/opt/build/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: recordsC0OoK9hOGIMeta?.name ?? "records",
    path: recordsC0OoK9hOGIMeta?.path ?? "/records",
    meta: recordsC0OoK9hOGIMeta || {},
    alias: recordsC0OoK9hOGIMeta?.alias || [],
    redirect: recordsC0OoK9hOGIMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records.vue").then(m => m.default || m),
    children: [
  {
    name: editvuwLf13hSVMeta?.name ?? "records-alabama-record_id-edit",
    path: editvuwLf13hSVMeta?.path ?? "alabama/:record_id()/edit",
    meta: editvuwLf13hSVMeta || {},
    alias: editvuwLf13hSVMeta?.alias || [],
    redirect: editvuwLf13hSVMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/alabama/[record_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: create3eDLuTi6pZMeta?.name ?? "records-alabama-create",
    path: create3eDLuTi6pZMeta?.path ?? "alabama/create",
    meta: create3eDLuTi6pZMeta || {},
    alias: create3eDLuTi6pZMeta?.alias || [],
    redirect: create3eDLuTi6pZMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/alabama/create.vue").then(m => m.default || m)
  },
  {
    name: index1pd5d9tH9GMeta?.name ?? "records-alabama",
    path: index1pd5d9tH9GMeta?.path ?? "alabama",
    meta: index1pd5d9tH9GMeta || {},
    alias: index1pd5d9tH9GMeta?.alias || [],
    redirect: index1pd5d9tH9GMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/alabama/index.vue").then(m => m.default || m)
  },
  {
    name: editI96roreSWdMeta?.name ?? "records-florida-record_id-edit",
    path: editI96roreSWdMeta?.path ?? "florida/:record_id()/edit",
    meta: editI96roreSWdMeta || {},
    alias: editI96roreSWdMeta?.alias || [],
    redirect: editI96roreSWdMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/florida/[record_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createLu2EDdyDJuMeta?.name ?? "records-florida-create",
    path: createLu2EDdyDJuMeta?.path ?? "florida/create",
    meta: createLu2EDdyDJuMeta || {},
    alias: createLu2EDdyDJuMeta?.alias || [],
    redirect: createLu2EDdyDJuMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/florida/create.vue").then(m => m.default || m)
  },
  {
    name: indexYnUaY46es9Meta?.name ?? "records-florida",
    path: indexYnUaY46es9Meta?.path ?? "florida",
    meta: indexYnUaY46es9Meta || {},
    alias: indexYnUaY46es9Meta?.alias || [],
    redirect: indexYnUaY46es9Meta?.redirect,
    component: () => import("/opt/build/repo/pages/records/florida/index.vue").then(m => m.default || m)
  },
  {
    name: editi4AJ7Yzty9Meta?.name ?? "records-georgia-record_id-edit",
    path: editi4AJ7Yzty9Meta?.path ?? "georgia/:record_id()/edit",
    meta: editi4AJ7Yzty9Meta || {},
    alias: editi4AJ7Yzty9Meta?.alias || [],
    redirect: editi4AJ7Yzty9Meta?.redirect,
    component: () => import("/opt/build/repo/pages/records/georgia/[record_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: create6WpUiTwP1SMeta?.name ?? "records-georgia-create",
    path: create6WpUiTwP1SMeta?.path ?? "georgia/create",
    meta: create6WpUiTwP1SMeta || {},
    alias: create6WpUiTwP1SMeta?.alias || [],
    redirect: create6WpUiTwP1SMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/georgia/create.vue").then(m => m.default || m)
  },
  {
    name: indexrthmXN2hhyMeta?.name ?? "records-georgia",
    path: indexrthmXN2hhyMeta?.path ?? "georgia",
    meta: indexrthmXN2hhyMeta || {},
    alias: indexrthmXN2hhyMeta?.alias || [],
    redirect: indexrthmXN2hhyMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/georgia/index.vue").then(m => m.default || m)
  },
  {
    name: editmuTIfY2E6yMeta?.name ?? "records-iIllinois-record_id-edit",
    path: editmuTIfY2E6yMeta?.path ?? "iIllinois/:record_id()/edit",
    meta: editmuTIfY2E6yMeta || {},
    alias: editmuTIfY2E6yMeta?.alias || [],
    redirect: editmuTIfY2E6yMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/iIllinois/[record_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: create4WHHSNFLUmMeta?.name ?? "records-iIllinois-create",
    path: create4WHHSNFLUmMeta?.path ?? "iIllinois/create",
    meta: create4WHHSNFLUmMeta || {},
    alias: create4WHHSNFLUmMeta?.alias || [],
    redirect: create4WHHSNFLUmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/iIllinois/create.vue").then(m => m.default || m)
  },
  {
    name: indexLPdTYsg3qGMeta?.name ?? "records-iIllinois",
    path: indexLPdTYsg3qGMeta?.path ?? "iIllinois",
    meta: indexLPdTYsg3qGMeta || {},
    alias: indexLPdTYsg3qGMeta?.alias || [],
    redirect: indexLPdTYsg3qGMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/iIllinois/index.vue").then(m => m.default || m)
  },
  {
    name: editDiNQZ3yUR0Meta?.name ?? "records-mississippi-record_id-edit",
    path: editDiNQZ3yUR0Meta?.path ?? "mississippi/:record_id()/edit",
    meta: editDiNQZ3yUR0Meta || {},
    alias: editDiNQZ3yUR0Meta?.alias || [],
    redirect: editDiNQZ3yUR0Meta?.redirect,
    component: () => import("/opt/build/repo/pages/records/mississippi/[record_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createdybCH2sDtoMeta?.name ?? "records-mississippi-create",
    path: createdybCH2sDtoMeta?.path ?? "mississippi/create",
    meta: createdybCH2sDtoMeta || {},
    alias: createdybCH2sDtoMeta?.alias || [],
    redirect: createdybCH2sDtoMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/mississippi/create.vue").then(m => m.default || m)
  },
  {
    name: index4Vu5PwaTpyMeta?.name ?? "records-mississippi",
    path: index4Vu5PwaTpyMeta?.path ?? "mississippi",
    meta: index4Vu5PwaTpyMeta || {},
    alias: index4Vu5PwaTpyMeta?.alias || [],
    redirect: index4Vu5PwaTpyMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/mississippi/index.vue").then(m => m.default || m)
  },
  {
    name: editFX5hBdLyHIMeta?.name ?? "records-new-jersey-record_id-edit",
    path: editFX5hBdLyHIMeta?.path ?? "new-jersey/:record_id()/edit",
    meta: editFX5hBdLyHIMeta || {},
    alias: editFX5hBdLyHIMeta?.alias || [],
    redirect: editFX5hBdLyHIMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/new-jersey/[record_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createhMTTRBqCGZMeta?.name ?? "records-new-jersey-create",
    path: createhMTTRBqCGZMeta?.path ?? "new-jersey/create",
    meta: createhMTTRBqCGZMeta || {},
    alias: createhMTTRBqCGZMeta?.alias || [],
    redirect: createhMTTRBqCGZMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/new-jersey/create.vue").then(m => m.default || m)
  },
  {
    name: index1rZDvj2nvQMeta?.name ?? "records-new-jersey",
    path: index1rZDvj2nvQMeta?.path ?? "new-jersey",
    meta: index1rZDvj2nvQMeta || {},
    alias: index1rZDvj2nvQMeta?.alias || [],
    redirect: index1rZDvj2nvQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/new-jersey/index.vue").then(m => m.default || m)
  },
  {
    name: edit6ES7MotAVFMeta?.name ?? "records-new-york-record_id-edit",
    path: edit6ES7MotAVFMeta?.path ?? "new-york/:record_id()/edit",
    meta: edit6ES7MotAVFMeta || {},
    alias: edit6ES7MotAVFMeta?.alias || [],
    redirect: edit6ES7MotAVFMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/new-york/[record_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createaaFZSVXqMlMeta?.name ?? "records-new-york-create",
    path: createaaFZSVXqMlMeta?.path ?? "new-york/create",
    meta: createaaFZSVXqMlMeta || {},
    alias: createaaFZSVXqMlMeta?.alias || [],
    redirect: createaaFZSVXqMlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/new-york/create.vue").then(m => m.default || m)
  },
  {
    name: indexDFs6r5W82nMeta?.name ?? "records-new-york",
    path: indexDFs6r5W82nMeta?.path ?? "new-york",
    meta: indexDFs6r5W82nMeta || {},
    alias: indexDFs6r5W82nMeta?.alias || [],
    redirect: indexDFs6r5W82nMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/new-york/index.vue").then(m => m.default || m)
  },
  {
    name: editiTIB2B4QVPMeta?.name ?? "records-tennessee-record_id-edit",
    path: editiTIB2B4QVPMeta?.path ?? "tennessee/:record_id()/edit",
    meta: editiTIB2B4QVPMeta || {},
    alias: editiTIB2B4QVPMeta?.alias || [],
    redirect: editiTIB2B4QVPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/tennessee/[record_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createC4dB8mHEtIMeta?.name ?? "records-tennessee-create",
    path: createC4dB8mHEtIMeta?.path ?? "tennessee/create",
    meta: createC4dB8mHEtIMeta || {},
    alias: createC4dB8mHEtIMeta?.alias || [],
    redirect: createC4dB8mHEtIMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/tennessee/create.vue").then(m => m.default || m)
  },
  {
    name: indexMkVPWH8KpYMeta?.name ?? "records-tennessee",
    path: indexMkVPWH8KpYMeta?.path ?? "tennessee",
    meta: indexMkVPWH8KpYMeta || {},
    alias: indexMkVPWH8KpYMeta?.alias || [],
    redirect: indexMkVPWH8KpYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/tennessee/index.vue").then(m => m.default || m)
  },
  {
    name: editXxwylp1zBGMeta?.name ?? "records-texas-record_id-edit",
    path: editXxwylp1zBGMeta?.path ?? "texas/:record_id()/edit",
    meta: editXxwylp1zBGMeta || {},
    alias: editXxwylp1zBGMeta?.alias || [],
    redirect: editXxwylp1zBGMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/texas/[record_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createHRzXVcVU25Meta?.name ?? "records-texas-create",
    path: createHRzXVcVU25Meta?.path ?? "texas/create",
    meta: createHRzXVcVU25Meta || {},
    alias: createHRzXVcVU25Meta?.alias || [],
    redirect: createHRzXVcVU25Meta?.redirect,
    component: () => import("/opt/build/repo/pages/records/texas/create.vue").then(m => m.default || m)
  },
  {
    name: indexoxFFKz9iyhMeta?.name ?? "records-texas",
    path: indexoxFFKz9iyhMeta?.path ?? "texas",
    meta: indexoxFFKz9iyhMeta || {},
    alias: indexoxFFKz9iyhMeta?.alias || [],
    redirect: indexoxFFKz9iyhMeta?.redirect,
    component: () => import("/opt/build/repo/pages/records/texas/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: registeryZbpxRGV6SMeta?.name ?? undefined,
    path: registeryZbpxRGV6SMeta?.path ?? "/register",
    meta: registeryZbpxRGV6SMeta || {},
    alias: registeryZbpxRGV6SMeta?.alias || [],
    redirect: registeryZbpxRGV6SMeta?.redirect,
    component: () => import("/opt/build/repo/pages/register.vue").then(m => m.default || m),
    children: [
  {
    name: indexllN7HpClHUMeta?.name ?? "register",
    path: indexllN7HpClHUMeta?.path ?? "",
    meta: indexllN7HpClHUMeta || {},
    alias: indexllN7HpClHUMeta?.alias || [],
    redirect: indexllN7HpClHUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: oauthm8Jle9RACKMeta?.name ?? "register-oauth",
    path: oauthm8Jle9RACKMeta?.path ?? "oauth",
    meta: oauthm8Jle9RACKMeta || {},
    alias: oauthm8Jle9RACKMeta?.alias || [],
    redirect: oauthm8Jle9RACKMeta?.redirect,
    component: () => import("/opt/build/repo/pages/register/oauth.vue").then(m => m.default || m)
  },
  {
    name: select_45oauth81AKiTF2WfMeta?.name ?? "register-select-oauth",
    path: select_45oauth81AKiTF2WfMeta?.path ?? "select-oauth",
    meta: select_45oauth81AKiTF2WfMeta || {},
    alias: select_45oauth81AKiTF2WfMeta?.alias || [],
    redirect: select_45oauth81AKiTF2WfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/register/select-oauth.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sessionsN4huw98JZDMeta?.name ?? undefined,
    path: sessionsN4huw98JZDMeta?.path ?? "/sessions",
    meta: sessionsN4huw98JZDMeta || {},
    alias: sessionsN4huw98JZDMeta?.alias || [],
    redirect: sessionsN4huw98JZDMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions.vue").then(m => m.default || m),
    children: [
  {
    name: detailsUWiJAmOr7UMeta?.name ?? "sessions-id-associate-user_id-details",
    path: detailsUWiJAmOr7UMeta?.path ?? ":id()/associate/:user_id()/details",
    meta: detailsUWiJAmOr7UMeta || {},
    alias: detailsUWiJAmOr7UMeta?.alias || [],
    redirect: detailsUWiJAmOr7UMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/associate/[user_id]/details.vue").then(m => m.default || m)
  },
  {
    name: indexzbdcsNzKRLMeta?.name ?? "sessions-id-associate-user_id",
    path: indexzbdcsNzKRLMeta?.path ?? ":id()/associate/:user_id()",
    meta: indexzbdcsNzKRLMeta || {},
    alias: indexzbdcsNzKRLMeta?.alias || [],
    redirect: indexzbdcsNzKRLMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/associate/[user_id]/index.vue").then(m => m.default || m)
  },
  {
    name: roomVxwAHPbxgRMeta?.name ?? "sessions-id-associate-user_id-room",
    path: roomVxwAHPbxgRMeta?.path ?? ":id()/associate/:user_id()/room",
    meta: roomVxwAHPbxgRMeta || {},
    alias: roomVxwAHPbxgRMeta?.alias || [],
    redirect: roomVxwAHPbxgRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/associate/[user_id]/room.vue").then(m => m.default || m)
  },
  {
    name: waiting_45room6PiHll6YqWMeta?.name ?? "sessions-id-associate-user_id-waiting-room",
    path: waiting_45room6PiHll6YqWMeta?.path ?? ":id()/associate/:user_id()/waiting-room",
    meta: waiting_45room6PiHll6YqWMeta || {},
    alias: waiting_45room6PiHll6YqWMeta?.alias || [],
    redirect: waiting_45room6PiHll6YqWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/associate/[user_id]/waiting-room.vue").then(m => m.default || m)
  },
  {
    name: authFQRwju86WIMeta?.name ?? "sessions-id-client-user_id-auth",
    path: authFQRwju86WIMeta?.path ?? ":id()/client/:user_id()/auth",
    meta: authFQRwju86WIMeta || {},
    alias: authFQRwju86WIMeta?.alias || [],
    redirect: authFQRwju86WIMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/client/[user_id]/auth.vue").then(m => m.default || m)
  },
  {
    name: denied1z48I7upTiMeta?.name ?? "sessions-id-client-user_id-denied",
    path: denied1z48I7upTiMeta?.path ?? ":id()/client/:user_id()/denied",
    meta: denied1z48I7upTiMeta || {},
    alias: denied1z48I7upTiMeta?.alias || [],
    redirect: denied1z48I7upTiMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/client/[user_id]/denied.vue").then(m => m.default || m)
  },
  {
    name: detailsLtOVUaLf8hMeta?.name ?? "sessions-id-client-user_id-details",
    path: detailsLtOVUaLf8hMeta?.path ?? ":id()/client/:user_id()/details",
    meta: detailsLtOVUaLf8hMeta || {},
    alias: detailsLtOVUaLf8hMeta?.alias || [],
    redirect: detailsLtOVUaLf8hMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/client/[user_id]/details.vue").then(m => m.default || m)
  },
  {
    name: documentsofTwpWmAalMeta?.name ?? "sessions-id-client-user_id-documents",
    path: documentsofTwpWmAalMeta?.path ?? ":id()/client/:user_id()/documents",
    meta: documentsofTwpWmAalMeta || {},
    alias: documentsofTwpWmAalMeta?.alias || [],
    redirect: documentsofTwpWmAalMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/client/[user_id]/documents.vue").then(m => m.default || m)
  },
  {
    name: downloaddgsCwzc61UMeta?.name ?? "sessions-id-client-user_id-download",
    path: downloaddgsCwzc61UMeta?.path ?? ":id()/client/:user_id()/download",
    meta: downloaddgsCwzc61UMeta || {},
    alias: downloaddgsCwzc61UMeta?.alias || [],
    redirect: downloaddgsCwzc61UMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/client/[user_id]/download.vue").then(m => m.default || m)
  },
  {
    name: indexA2lirZ54mEMeta?.name ?? "sessions-id-client-user_id",
    path: indexA2lirZ54mEMeta?.path ?? ":id()/client/:user_id()",
    meta: indexA2lirZ54mEMeta || {},
    alias: indexA2lirZ54mEMeta?.alias || [],
    redirect: indexA2lirZ54mEMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/client/[user_id]/index.vue").then(m => m.default || m)
  },
  {
    name: kbafDK2rnu9RUMeta?.name ?? "sessions-id-client-user_id-kba",
    path: kbafDK2rnu9RUMeta?.path ?? ":id()/client/:user_id()/kba",
    meta: kbafDK2rnu9RUMeta || {},
    alias: kbafDK2rnu9RUMeta?.alias || [],
    redirect: kbafDK2rnu9RUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/client/[user_id]/kba.vue").then(m => m.default || m)
  },
  {
    name: previewmNROKE96X3Meta?.name ?? "sessions-id-client-user_id-preview",
    path: previewmNROKE96X3Meta?.path ?? ":id()/client/:user_id()/preview",
    meta: previewmNROKE96X3Meta || {},
    alias: previewmNROKE96X3Meta?.alias || [],
    redirect: previewmNROKE96X3Meta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/client/[user_id]/preview.vue").then(m => m.default || m)
  },
  {
    name: questionsmBx72aRJLJMeta?.name ?? "sessions-id-client-user_id-questions",
    path: questionsmBx72aRJLJMeta?.path ?? ":id()/client/:user_id()/questions",
    meta: questionsmBx72aRJLJMeta || {},
    alias: questionsmBx72aRJLJMeta?.alias || [],
    redirect: questionsmBx72aRJLJMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/client/[user_id]/questions.vue").then(m => m.default || m)
  },
  {
    name: roomxmx5sS5ZhPMeta?.name ?? "sessions-id-client-user_id-room",
    path: roomxmx5sS5ZhPMeta?.path ?? ":id()/client/:user_id()/room",
    meta: roomxmx5sS5ZhPMeta || {},
    alias: roomxmx5sS5ZhPMeta?.alias || [],
    redirect: roomxmx5sS5ZhPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/client/[user_id]/room.vue").then(m => m.default || m)
  },
  {
    name: switchvpulPnbiBMMeta?.name ?? "sessions-id-client-user_id-switch",
    path: switchvpulPnbiBMMeta?.path ?? ":id()/client/:user_id()/switch",
    meta: switchvpulPnbiBMMeta || {},
    alias: switchvpulPnbiBMMeta?.alias || [],
    redirect: switchvpulPnbiBMMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/client/[user_id]/switch.vue").then(m => m.default || m)
  },
  {
    name: waiting_45roomjIpR5DWoqgMeta?.name ?? "sessions-id-client-user_id-waiting-room",
    path: waiting_45roomjIpR5DWoqgMeta?.path ?? ":id()/client/:user_id()/waiting-room",
    meta: waiting_45roomjIpR5DWoqgMeta || {},
    alias: waiting_45roomjIpR5DWoqgMeta?.alias || [],
    redirect: waiting_45roomjIpR5DWoqgMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/client/[user_id]/waiting-room.vue").then(m => m.default || m)
  },
  {
    name: editLClYUMianTMeta?.name ?? "sessions-id-documents-edit",
    path: editLClYUMianTMeta?.path ?? ":id()/documents/edit",
    meta: editLClYUMianTMeta || {},
    alias: editLClYUMianTMeta?.alias || [],
    redirect: editLClYUMianTMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/documents/edit.vue").then(m => m.default || m)
  },
  {
    name: index1Bv63MA5pjMeta?.name ?? "sessions-id-host-user_id",
    path: index1Bv63MA5pjMeta?.path ?? ":id()/host/:user_id()",
    meta: index1Bv63MA5pjMeta || {},
    alias: index1Bv63MA5pjMeta?.alias || [],
    redirect: index1Bv63MA5pjMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/host/[user_id]/index.vue").then(m => m.default || m)
  },
  {
    name: roomLR3Ui6umMDMeta?.name ?? "sessions-id-host-user_id-room",
    path: roomLR3Ui6umMDMeta?.path ?? ":id()/host/:user_id()/room",
    meta: roomLR3Ui6umMDMeta || {},
    alias: roomLR3Ui6umMDMeta?.alias || [],
    redirect: roomLR3Ui6umMDMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/host/[user_id]/room.vue").then(m => m.default || m)
  },
  {
    name: indexTLPotumsJJMeta?.name ?? "sessions-id-team-user_id",
    path: indexTLPotumsJJMeta?.path ?? ":id()/team/:user_id()",
    meta: indexTLPotumsJJMeta || {},
    alias: indexTLPotumsJJMeta?.alias || [],
    redirect: indexTLPotumsJJMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/team/[user_id]/index.vue").then(m => m.default || m)
  },
  {
    name: room56izYzaiKeMeta?.name ?? "sessions-id-team-user_id-room",
    path: room56izYzaiKeMeta?.path ?? ":id()/team/:user_id()/room",
    meta: room56izYzaiKeMeta || {},
    alias: room56izYzaiKeMeta?.alias || [],
    redirect: room56izYzaiKeMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/team/[user_id]/room.vue").then(m => m.default || m)
  },
  {
    name: waiting_45roomQErKsWyVLuMeta?.name ?? "sessions-id-team-user_id-waiting-room",
    path: waiting_45roomQErKsWyVLuMeta?.path ?? ":id()/team/:user_id()/waiting-room",
    meta: waiting_45roomQErKsWyVLuMeta || {},
    alias: waiting_45roomQErKsWyVLuMeta?.alias || [],
    redirect: waiting_45roomQErKsWyVLuMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/team/[user_id]/waiting-room.vue").then(m => m.default || m)
  },
  {
    name: autheaTmcoz9brMeta?.name ?? "sessions-id-witness-user_id-auth",
    path: autheaTmcoz9brMeta?.path ?? ":id()/witness/:user_id()/auth",
    meta: autheaTmcoz9brMeta || {},
    alias: autheaTmcoz9brMeta?.alias || [],
    redirect: autheaTmcoz9brMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/witness/[user_id]/auth.vue").then(m => m.default || m)
  },
  {
    name: deniedGPduTJzmCLMeta?.name ?? "sessions-id-witness-user_id-denied",
    path: deniedGPduTJzmCLMeta?.path ?? ":id()/witness/:user_id()/denied",
    meta: deniedGPduTJzmCLMeta || {},
    alias: deniedGPduTJzmCLMeta?.alias || [],
    redirect: deniedGPduTJzmCLMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/witness/[user_id]/denied.vue").then(m => m.default || m)
  },
  {
    name: detailsNdxhZhnARpMeta?.name ?? "sessions-id-witness-user_id-details",
    path: detailsNdxhZhnARpMeta?.path ?? ":id()/witness/:user_id()/details",
    meta: detailsNdxhZhnARpMeta || {},
    alias: detailsNdxhZhnARpMeta?.alias || [],
    redirect: detailsNdxhZhnARpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/witness/[user_id]/details.vue").then(m => m.default || m)
  },
  {
    name: indexc1QXq82ouvMeta?.name ?? "sessions-id-witness-user_id",
    path: indexc1QXq82ouvMeta?.path ?? ":id()/witness/:user_id()",
    meta: indexc1QXq82ouvMeta || {},
    alias: indexc1QXq82ouvMeta?.alias || [],
    redirect: indexc1QXq82ouvMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/witness/[user_id]/index.vue").then(m => m.default || m)
  },
  {
    name: kbaxaveO91oHVMeta?.name ?? "sessions-id-witness-user_id-kba",
    path: kbaxaveO91oHVMeta?.path ?? ":id()/witness/:user_id()/kba",
    meta: kbaxaveO91oHVMeta || {},
    alias: kbaxaveO91oHVMeta?.alias || [],
    redirect: kbaxaveO91oHVMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/witness/[user_id]/kba.vue").then(m => m.default || m)
  },
  {
    name: roomcSdLkvx0kBMeta?.name ?? "sessions-id-witness-user_id-room",
    path: roomcSdLkvx0kBMeta?.path ?? ":id()/witness/:user_id()/room",
    meta: roomcSdLkvx0kBMeta || {},
    alias: roomcSdLkvx0kBMeta?.alias || [],
    redirect: roomcSdLkvx0kBMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/witness/[user_id]/room.vue").then(m => m.default || m)
  },
  {
    name: waiting_45roomzdt5AVcVcqMeta?.name ?? "sessions-id-witness-user_id-waiting-room",
    path: waiting_45roomzdt5AVcVcqMeta?.path ?? ":id()/witness/:user_id()/waiting-room",
    meta: waiting_45roomzdt5AVcVcqMeta || {},
    alias: waiting_45roomzdt5AVcVcqMeta?.alias || [],
    redirect: waiting_45roomzdt5AVcVcqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/[id]/witness/[user_id]/waiting-room.vue").then(m => m.default || m)
  },
  {
    name: indexrpMW0DQkYiMeta?.name ?? "sessions",
    path: indexrpMW0DQkYiMeta?.path ?? "",
    meta: indexrpMW0DQkYiMeta || {},
    alias: indexrpMW0DQkYiMeta?.alias || [],
    redirect: indexrpMW0DQkYiMeta?.redirect,
    component: () => import("/opt/build/repo/pages/sessions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: switch4PFLqDQchJMeta?.name ?? "switch",
    path: switch4PFLqDQchJMeta?.path ?? "/switch",
    meta: switch4PFLqDQchJMeta || {},
    alias: switch4PFLqDQchJMeta?.alias || [],
    redirect: switch4PFLqDQchJMeta?.redirect,
    component: () => import("/opt/build/repo/pages/switch.vue").then(m => m.default || m)
  },
  {
    name: closedjjoznYf5MWMeta?.name ?? "system-closed",
    path: closedjjoznYf5MWMeta?.path ?? "/system/closed",
    meta: closedjjoznYf5MWMeta || {},
    alias: closedjjoznYf5MWMeta?.alias || [],
    redirect: closedjjoznYf5MWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/system/closed.vue").then(m => m.default || m)
  },
  {
    name: maintenanceikRuxCc89GMeta?.name ?? "system-maintenance",
    path: maintenanceikRuxCc89GMeta?.path ?? "/system/maintenance",
    meta: maintenanceikRuxCc89GMeta || {},
    alias: maintenanceikRuxCc89GMeta?.alias || [],
    redirect: maintenanceikRuxCc89GMeta?.redirect,
    component: () => import("/opt/build/repo/pages/system/maintenance.vue").then(m => m.default || m)
  },
  {
    name: not_45founduAKyhnbCbOMeta?.name ?? "system-not-found",
    path: not_45founduAKyhnbCbOMeta?.path ?? "/system/not-found",
    meta: not_45founduAKyhnbCbOMeta || {},
    alias: not_45founduAKyhnbCbOMeta?.alias || [],
    redirect: not_45founduAKyhnbCbOMeta?.redirect,
    component: () => import("/opt/build/repo/pages/system/not-found.vue").then(m => m.default || m)
  },
  {
    name: pendingONJjWPORT8Meta?.name ?? "system-pending",
    path: pendingONJjWPORT8Meta?.path ?? "/system/pending",
    meta: pendingONJjWPORT8Meta || {},
    alias: pendingONJjWPORT8Meta?.alias || [],
    redirect: pendingONJjWPORT8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/system/pending.vue").then(m => m.default || m)
  },
  {
    name: suspendedTnN1j4e4NIMeta?.name ?? "system-suspended",
    path: suspendedTnN1j4e4NIMeta?.path ?? "/system/suspended",
    meta: suspendedTnN1j4e4NIMeta || {},
    alias: suspendedTnN1j4e4NIMeta?.alias || [],
    redirect: suspendedTnN1j4e4NIMeta?.redirect,
    component: () => import("/opt/build/repo/pages/system/suspended.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/terms-of-service",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: editYxXwgfJjtNMeta?.name ?? "user-edit",
    path: editYxXwgfJjtNMeta?.path ?? "/user/edit",
    meta: editYxXwgfJjtNMeta || {},
    alias: editYxXwgfJjtNMeta?.alias || [],
    redirect: editYxXwgfJjtNMeta?.redirect,
    component: () => import("/opt/build/repo/pages/user/edit.vue").then(m => m.default || m)
  }
]
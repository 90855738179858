import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_axios@1.6.7_encodi_7mva3zgo7anl53j67q23cc6s4q/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/build/repo/middleware/auth.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_axios@1.6.7_encodi_7mva3zgo7anl53j67q23cc6s4q/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  account: () => import("/opt/build/repo/middleware/account.ts"),
  "client-login": () => import("/opt/build/repo/middleware/client-login.ts"),
  denied: () => import("/opt/build/repo/middleware/denied.ts"),
  "final-onboarding": () => import("/opt/build/repo/middleware/final-onboarding.ts"),
  host: () => import("/opt/build/repo/middleware/host.ts"),
  invite: () => import("/opt/build/repo/middleware/invite.ts"),
  loggin: () => import("/opt/build/repo/middleware/loggin.ts"),
  "non-app": () => import("/opt/build/repo/middleware/non-app.ts"),
  "oauth-invite": () => import("/opt/build/repo/middleware/oauth-invite.ts"),
  "oauth-register": () => import("/opt/build/repo/middleware/oauth-register.ts"),
  oauth: () => import("/opt/build/repo/middleware/oauth.ts"),
  onboarding: () => import("/opt/build/repo/middleware/onboarding.ts"),
  "participent-login": () => import("/opt/build/repo/middleware/participent-login.ts"),
  participent: () => import("/opt/build/repo/middleware/participent.ts"),
  "password-reset": () => import("/opt/build/repo/middleware/password-reset.ts"),
  redirect: () => import("/opt/build/repo/middleware/redirect.ts"),
  switch: () => import("/opt/build/repo/middleware/switch.ts")
}
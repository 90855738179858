import axios, { type InternalAxiosRequestConfig } from "axios";
import { throttle } from "radash";
export default defineNuxtPlugin((nuxtApp) => {
  // Set subdomain for multi-tenant
  const subdomain = window.location.hostname.split(".")[0] ?? "app";
  // Get runtime config
  const config = useRuntimeConfig();
  const api = axios.create({
    baseURL: config.public.apiBase,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "X-Tenant": subdomain,
    },
  });

  const debounced = throttle({ interval: 10000 }, function () {
    api.get("/sanctum/csrf-cookie");
  });
  function sanctum_cookie(config: InternalAxiosRequestConfig<any>) {
    if (
      config.method &&
      ["post", "put", "delete"].includes(config.method) &&
      !config.url?.includes("login") &&
      !config.url?.includes("register")
    ) {
      debounced();
    }

    return config;
  }

  api.interceptors.request.use(sanctum_cookie, null);

  return {
    provide: {
      axios: api,
    },
  };
});
import type { ToastOptions } from "@/types/toast";
import { promiseTimeout, useTimeout } from "@vueuse/core";
import { v4 as uuid } from "uuid";

export const useMessage = defineStore("toast", () => {
  const toasts = ref<ToastOptions[]>([]);
  const message = async (options: ToastOptions) => {
    const defaultOptions = {
      timeout: true,
      position: "bottom-right",
      time: 4000,
    };
    // Add toast
    const id = uuid();
    const toastData = {
      ...defaultOptions,
      ...options,
      id,
    };
    toasts.value.push({
      ...toastData,
    });
    if (toastData.timeout) {
      // Wait 4 seconds
      await promiseTimeout(toastData.time);

      toastData?.callback?.();
      // Remove toast
      toasts.value = toasts.value.filter(
        (toast: ToastOptions) => toast.id !== id,
      );
    }
  };

  const remove = (id: any) => {
    toasts.value = toasts.value.filter(
      (toast: ToastOptions) => toast.id !== id,
    );
  };

  return {
    message,
    toasts,
    remove,
  };
});

import Echo from "laravel-echo";
import Pusher from "pusher-js";

export default defineNuxtPlugin((nuxtApp) => {
  //@ts-ignore
  window.Pusher = Pusher;
  const { $axios } = useNuxtApp();
  const config = useRuntimeConfig();
  const echo = new Echo({
    broadcaster: "pusher",
    key: config.public.pusherAppKey,
    wssHost: config.public.pusherHost,
    wsHost: config.public.pusherHost,
    wsPort: config.public.pusherPort,
    wssPort: config.public.pusherPort,
    cluster: "",
    forceTLS: true,
    encrypted: true,
    disableStats: true,
    enabledTransports: ["wss", "ws"],
    authorizer: (channel) => {
      return {
        authorize: async (socketId: string, callback: Function) => {
          $axios.defaults.headers.common["X-Socket-Id"] = socketId;
          return $axios
            .post(
              "/broadcasting/auth",
              {
                socket_id: socketId,
                channel_name: channel.name,
              },
              {
                headers: {
                  "X-Socket-Id": socketId,
                },
              },
            )
            .then((res) => {
              // console.log(res.data);
              return callback(false, res.data);
            })
            .catch((err) => {
              // console.log(err);
              return callback(true, err);
            });
        },
      };
    },
  });

  return {
    provide: {
      echo,
    },
  };
});

import { Steps } from "@/types/global";
import { UserDetails } from "@/types/user";
import { useStorage } from "@vueuse/core";

export const useRegister = defineStore("register", () => {
  const steps = ref<Steps>({
    step: 1,
    route: "",
    noSerivce: false,
  });

  const trial = useStorage(
    "",
    ref({
      trial_period_token: "",
    }),
  );

  const userDetails = ref<UserDetails>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    otp_type: "",
    password: "",
    password_confirmation: "",
  });

  return { steps, userDetails, trial };
});

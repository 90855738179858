import revive_payload_client_kCoUJnibOB from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_axios@1.6.7_encodi_7mva3zgo7anl53j67q23cc6s4q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6iUDuh3h42 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_axios@1.6.7_encodi_7mva3zgo7anl53j67q23cc6s4q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ouH4eBLQFL from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_axios@1.6.7_encodi_7mva3zgo7anl53j67q23cc6s4q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_oTVB0M9BJq from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_axios@1.6.7_encodi_7mva3zgo7anl53j67q23cc6s4q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_eRd1o3ADiP from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_axios@1.6.7_encodi_7mva3zgo7anl53j67q23cc6s4q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_tvlH38KVNA from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.17.2_typescript@5.4.5_vue@3.4.26_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_43wZfkbSGe from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_axios@1.6.7_encodi_7mva3zgo7anl53j67q23cc6s4q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_cpknNKqdmL from "/opt/build/repo/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.22.7_rollup@4.17.2/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import slideovers_oISgA7A1k1 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.15.2_axios@1.6.7_focus-trap@7.5.4_nuxt@3.10.1_@parcel+watcher@2.4.1_@types+node@20_7mdqzzdk7ls7mn3ghd7q25lrie/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_dyUsTWgmOc from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.15.2_axios@1.6.7_focus-trap@7.5.4_nuxt@3.10.1_@parcel+watcher@2.4.1_@types+node@20_7mdqzzdk7ls7mn3ghd7q25lrie/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_eojkCNhaj1 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.15.2_axios@1.6.7_focus-trap@7.5.4_nuxt@3.10.1_@parcel+watcher@2.4.1_@types+node@20_7mdqzzdk7ls7mn3ghd7q25lrie/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_0es7dvT31Z from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.17.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_WNqg8TsmSp from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.4.1_@types+node@20.12.8_@unocss+reset@0.59.4_axios@1.6.7_encodi_7mva3zgo7anl53j67q23cc6s4q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_QMFgzss1s4 from "/opt/build/repo/plugins/axios.ts";
import echo_Too0shxWI0 from "/opt/build/repo/plugins/echo.ts";
import i18n_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import plyr_m1vubbxwTm from "/opt/build/repo/plugins/plyr.ts";
import stripe_6FZUdDHpIZ from "/opt/build/repo/plugins/stripe.ts";
import vue_final_modal_pML93k5qcp from "/opt/build/repo/plugins/vue-final-modal.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_kCoUJnibOB,
  unhead_6iUDuh3h42,
  router_ouH4eBLQFL,
  payload_client_oTVB0M9BJq,
  check_outdated_build_client_eRd1o3ADiP,
  plugin_vue3_tvlH38KVNA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_43wZfkbSGe,
  plugin_cpknNKqdmL,
  slideovers_oISgA7A1k1,
  modals_dyUsTWgmOc,
  colors_eojkCNhaj1,
  plugin_client_0es7dvT31Z,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_WNqg8TsmSp,
  axios_QMFgzss1s4,
  echo_Too0shxWI0,
  i18n_VfGcjrvSkj,
  plyr_m1vubbxwTm,
  stripe_6FZUdDHpIZ,
  vue_final_modal_pML93k5qcp,
  vue_query_wrmMkNpEpe
]
import { loadStripe } from "@stripe/stripe-js";

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig();
  const stripePromise = loadStripe(config.public.stripeKey);

  return {
    provide: {
      stripe: await stripePromise,
    },
  };
});

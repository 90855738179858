<script setup lang="ts">
useHead({
  titleTemplate(title) {
    return title ? title + " - Notary.io" : "Notary.io";
  },
});

const toast = useMessage();

const logSomeError = (error: any) => {
  console.error("Error captured", error);
  toast.message({
    title: "Error",
    message: error,
    type: "error",
  });
};
</script>

<template>
  <NuxtLoadingIndicator color="#06C972" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <CommonToast />
</template>

import { useQuery } from "@tanstack/vue-query";
import type { CountryCode } from "libphonenumber-js";

//States Helper
export const useStateOptions = async () => {
  const { $axios } = useNuxtApp();

  const {
    data: states,
    isFetching: isFetchingStates,
    refetch: refetchStates,
  } = useQuery({
    queryKey: ["states"],
    queryFn: async () => await $axios.get(`/states`).then((res) => res.data),
    refetchOnMount: false,
  });

  const stateOptions = computed(
    () =>
      states.value?.map((state: any) => ({
        label: state.state,
        value: state.abbreviation,
      })) ?? [],
  );
  return { stateOptions };
};

export const useCountyOptions = async (state: any) => {
  const { $axios } = useNuxtApp();
  //console.log(state);

  const {
    data: counties,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["counties", state],
    queryFn: async () =>
      await $axios
        .get(`/counties`, {
          params: {
            per_page: 300,
            state: state.value,
          },
        })
        .then((res) => res.data.data),
    refetchOnMount: false,
  });

  const countyOptions = computed(
    () =>
      counties.value?.map((county: any) => ({
        label: county.name,
        value: county.name,
      })) ?? [],
  );
  return { countyOptions, isFetching, refetch };
};

//Country Helper
export const useCountryOptions = async () => {
  const { $axios } = useNuxtApp();

  const {
    data: countries,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["countries"],
    queryFn: async () => await $axios.get(`/countries`).then((res) => res.data),
    refetchOnMount: false,
  });

  const countryOptions = computed(
    () =>
      countries.value?.map((country: any) => ({
        label: country.country,
        value: country.abbreviation,
      })) ?? [],
  );
  return { countryOptions, isFetching, refetch };
};

//Country Abr Helper

export const useCountryAbrOptions = async () => {
  const { $axios } = useNuxtApp();
  const {
    data: countries,
    isFetching: isFetchingCountries,
    refetch: refetchCountries,
  } = useQuery({
    queryKey: ["countries-arbs"],
    queryFn: async () => await $axios.get(`/countries`).then((res) => res.data),
    refetchOnMount: false,
  });

  const countryAbrOptions = computed(
    () =>
      countries.value?.map(
        (country: any) =>
          ({
            label: country.dial_code + " " + country.abbreviation,
            value: country.abbreviation,
            formatted: country.formatted,
          } as { label: string; value: CountryCode }),
      ) ?? [],
  );
  return { countryAbrOptions };
};

export const currency = (value: number, type: string) => {
  if (type === "us") {
    //format number for US Dollar
    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return USDollar.format(value);
  }

  if (type === "en") {
    // format number to British pounds
    let pounds = Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return pounds.format(value);
  }

  if (type === "in") {
    // format number to Indian rupee
    let rupee = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    });
    return rupee.format(value);
  }

  if (type === "eu") {
    // format number to Euro
    let euro = Intl.NumberFormat("en-DE", {
      style: "currency",
      currency: "EUR",
    });
    return euro.format(value);
  }
};

import { useMiddleware } from "@/store/middleware";
import { useRegister } from "@/store/register";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuth();
  const register = useRegister();
  const host = window.location.host;
  const subdomain = host.split(".")[0];
  // Check is session then return to allow participant middleware to control

  if (to.query?.trial_period_token) {
    register.trial.trial_period_token = to.query?.trial_period_token as string;
  }

  if (to.meta.session) return;
  if (to.meta.register) return;
  if (to.meta.maintenance) return;

  const middleware = useMiddleware();
  const config = useRuntimeConfig();

  if (config.public.maintenanceMode) {
    console.log(config.public.maintenanceMode);
    return navigateTo(`${config.public.primaryURL}/system/maintenance`, {
      external: true,
    });
  }

  if (to.params?.user_id) {
    const pathSplit = to.fullPath.split("/");
    auth.model_type = pathSplit[3];
  }

  //Check is subdomain exists
  if (auth?.subdomain !== "app") {
    try {
      await middleware.subdomain(auth?.subdomain);
    } catch (e) {
      return navigateTo(`${config.public.primaryURL}/login`, {
        external: true,
      });
    }
  }

  //Default route no index
  if (to.path === "/") {
    return navigateTo("/login");
  }

  //Check if route is public
  if (to.meta.noAuth) return;

  //  Check if user is logged in if not refetch user cookie
  if (!auth.isSuccess) {
    try {
      await auth.refetch();
    } catch (e) {
      return navigateTo("/login");
    }
  }

  //Check id user has access to that account if not redirect to switch only if subdomain !== app
  const account = ref(null);
  if (auth?.subdomain !== "app") {
    try {
      account.value = await middleware.account();
    } catch (e) {
      if (auth.isSuccess) {
        return navigateTo(`${config.public.primaryURL}/switch`, {
          external: true,
        });
      } else {
        return navigateTo(`${config.public.primaryURL}/login`, {
          external: true,
        });
      }
    }
  }

  //If user is logged in check account status and redirect to correct page
  if (auth.isSuccess) {
    if (to.meta.onboarding) return;
    checkStatus(account.value?.account_status);
  } else {
    return navigateTo("/login");
  }

  function checkStatus(status: string) {
    switch (status) {
      case "ONBOARDING":
        return navigateTo(`${config.public.primaryURL}/onboarding`, {
          external: true,
        });
      case "ACTIVE":
        return;
      case "SUSPENDED":
        return navigateTo(`${config.public.primaryURL}/system/suspended`, {
          external: true,
        });
      case "PENDING":
        return navigateTo(`${config.public.primaryURL}/system/pending`, {
          external: true,
        });
      case "CLOSED":
        return navigateTo(`${config.public.primaryURL}/system/closed`, {
          external: true,
        });
      default:
        return;
    }
  }
});

import type { User } from "@/types/user";
import { useMutation, useQuery } from "@tanstack/vue-query";

export const useAuth = defineStore("auth", () => {
  const { $axios } = useNuxtApp();
  const model_type = ref("");
  const subdomain = window.location.hostname.split(".").slice(0, -2).join(".");
  const host = window.location.host;

  const account = ref(null);
  const user = ref<User | null>(null);
  const accounts = ref([]);
  const mouse = ref({ x: 0, y: 0 });
  const verification_error = ref(null);

  const {
    data: allAccounts,
    refetch: refetchAccounts,
    isSuccess: hasAllAccounts,
    error: errorAllAccounts,
  } = useQuery({
    queryKey: ["accounts"],
    queryFn: async () => {
      try {
        return await $axios.get(`/v1/accounts`).then((res) => res.data);
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    enabled: computed(() => Boolean(user.value)),
  });

  watch(
    () => allAccounts.value,
    (value) => {
      accounts.value = value.data;
    },
  );

  const { data: accountData, refetch: refetchAccount } = useQuery({
    queryKey: ["account", subdomain],
    queryFn: async ({ queryKey }: any) => {
      try {
        return await $axios
          .get(`/v1/accounts/${queryKey[1]}`)
          .then((res) => res.data);
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    enabled: computed(() => Boolean(subdomain && subdomain !== "app")),
  });

  watch(
    () => accountData.value,
    (value) => {
      account.value = value;
    },
  );

  const {
    mutateAsync: refetch,
    isSuccess,
    isPending,
  } = useMutation({
    mutationFn: async () =>
      await $axios.get<User>("/user").then((res) => res.data),
    onSuccess: (data) => {
      user.value = data;
    },
    onError: (error) => {
      user.value = null;
      console.log(error);
    },
  });

  const { mutateAsync: logout } = useMutation({
    mutationFn: async (data) => {
      await $axios.post("/logout");
    },
    onSuccess: () => {
      user.value = null;
      navigateTo(`/login`);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { mutateAsync: logoutNoRoute, isSuccess: isLoggedOutRoute } =
    useMutation({
      mutationFn: async (data) => {
        await $axios.post("/logout");
      },
      onSuccess: () => {
        user.value = null;
      },
      onError: (error) => {
        console.log(error);
      },
    });

  return {
    host,
    accounts,
    refetchAccounts,
    mouse,
    isLoggedOutRoute,
    refetchAccount,
    logoutNoRoute,
    subdomain,
    account,
    model_type,
    user,
    isPending,
    refetch,
    isSuccess,
    logout,
    verification_error,
  };
});

<script setup lang="ts">
import type { FormKitNode } from "@formkit/core";
import { FormKit } from "@formkit/vue";
import type { CountryCode } from "libphonenumber-js";
import parsePhoneNumber, {
  parsePhoneNumberFromString,
} from "libphonenumber-js";
import { v4 as uuid } from "uuid";

const { countryAbrOptions } = await useCountryAbrOptions();
const props = defineProps({
  phone: String,
  label: {
    type: String,
    default: "Phone",
  },
});

const uuidKey = uuid();

const phone = toRef(props, "phone");

const emits = defineEmits<{
  (e: "update:phone", value: string): void;
}>();

const form = ref<{
  phone: string;
  code: CountryCode;
  formatted: string;
  formattedNum: string;
  isValid: boolean;
}>({
  phone: "",
  code: "US",
  formatted: "(###) ###-####",
  formattedNum: "",
  isValid: false,
});

watch(
  () => phone.value,
  () => {
    form.value.phone = String(phone.value);
    const currentPhone = form.value.phone
      ? parsePhoneNumberFromString(form.value.phone, form.value.code)
      : null;
    if (currentPhone) {
      form.value.code = currentPhone?.country ?? form.value.code;
      form.value.phone = currentPhone?.nationalNumber;
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => form.value.code,
  (val) => {
    if (val) {
      form.value.phone = "";
      form.value.formatted = countryAbrOptions.value.find(
        (Option) => Option.value === val,
      ).formatted;
    }
  },
);

const onUpdated = (val: any) => {
  // Set Value Phone
  form.value.phone = val;
  // Format and check number
  const newPhoneNumber = parsePhoneNumber(val, form.value.code);

  if (newPhoneNumber && newPhoneNumber.isValid()) {
    form.value.formattedNum = newPhoneNumber.number ?? null;
    form.value.isValid = true;
    emits("update:phone", form.value.formattedNum.replace("+", ""));
  } else {
    // Clear the formatted number and mark it as invalid
    form.value.formattedNum = "";
    form.value.isValid = false;
    // Optionally emit null or handle the case where the phone is invalid
    emits("update:phone", null);
  }
};

function validphone(node: FormKitNode): boolean {
  const newPhoneNumber = parsePhoneNumber(
    node.value as string,
    form.value.code,
  );
  return newPhoneNumber?.isValid() ?? false;
}
const isFormValid = ref(true);

const handleValidation = (valid: boolean) => {
  isFormValid.value = valid;
};
</script>

<template>
  <div class="flex flex-col w-full">
    <div class="flex gap-3">
      <!-- old select trying new
        <FormKit
        v-bind="$attrs"
        input-class=""
        outer-class="!w-[175px]"
        wrapper-class="!rounded -mr-[1px] focus-within:!ring-0 focus:!outline-0 flex "
        type="autocomplete"
        :name="`code_${uuidKey}`"
        label="Code"
        :options="countryAbrOptions"
        placeholder="Select Country Code"
        v-model="form.code"
      /> -->
      <InputsSelect
        @validation="handleValidation"
        :options="countryAbrOptions"
        label="Code"
        v-model="form.code"
        wrapper-class="!max-w-[150px] !min-w-[150px]"
      />
      <FormKit
        v-bind="$attrs"
        type="mask"
        :name="`phone_${uuidKey}`"
        :key="form.code"
        :mask="form.formatted"
        :label="label"
        :model-value="form.phone"
        placeholder="Phone Number"
        @input="onUpdated"
        show-mask="false"
        unmask-value="true"
        :validation-rules="{ validphone }"
        :validation-messages="{
          validphone: 'Please enter a valid phone number',
        }"
        autocomplete="off"
      />
    </div>
  </div>
</template>

<style></style>

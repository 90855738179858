<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { vElementVisibility, vOnClickOutside } from "@vueuse/components";
import { useEventListener } from "@vueuse/core";
import { clone } from "radash";

const props = defineProps<{
  type?: string;
  label?: string;
  options: { label: string; value: string | boolean }[];
  wrapperClass?: string;
  emptyMessage?: string;
  searchable?: boolean;
  modelValue?: any;
  loading?: boolean;
  debounce?: number;
  hasNextPage?: boolean;
  fetchingNextPage?: boolean;
  validateMessage?: string;
  hideCursor?: boolean;
  preventKeydown?: boolean;
  disabled?: boolean;
}>();

const emits = defineEmits<{
  (e: "search", value: string): void;
  (e: "update:modelValue", value: any): void;
  (e: "select", value: any): void;
  (e: "nextPage", value: boolean): void;
  (e: "add", value: boolean): void;
  (e: "invalidSelection", message: string): void;
  (e: "validation", valid: boolean): void;
}>();

const select = ref<HTMLDivElement>();
const selectInput = ref<HTMLInputElement>();
const list = ref<HTMLUListElement>();

onMounted(() => {
  selectInput.value = select.value?.$el.querySelector(".formkit-input");

  useEventListener(select.value, "keydown", (e) => {
    e.stopPropagation();
    handleKeyDown(e);
  });

  useEventListener(selectInput.value, "focus", (e) => {
    e.stopPropagation();
    showItems.value = true;
  });

  useEventListener(selectInput.value, "blur", (e) => {
    validateInput(e.target.value);
  });
});

const items = ref();
const itemClone = ref();

const slots = useSlots();

const form = ref({
  label: "",
  value: null,
});

watch(
  () => [props.modelValue, props.options],
  ([model, options]) => {
    if (options) {
      items.value = clone(options);
      itemClone.value = clone(options);
    }

    if (![null, "undefined", undefined, ""].includes(model) && options) {
      form.value = clone(options.find((item) => item.value === model)) || {};
    }
  },
  { immediate: true, deep: true, flush: "sync" },
);

const showItems = ref(false);
const activeIndex = ref(-1);
const validationMessage = ref(props.validateMessage);

const setActiveIndex = (index) => {
  activeIndex.value = index;
};

const selectItem = async (item) => {
  if (!item || item.label === undefined || item.value === undefined) {
    // console.warn("Attempted to select an invalid item:", item);
    return;
  }

  form.value.label = item.label;
  form.value.value = item.value;

  emits("update:modelValue", item.value);
  emits("select", item);

  showItems.value = false;
  activeIndex.value = -1;
  validationMessage.value = "";
  emits("validation", true);
};

const handleKeyDown = (event) => {
  if (event.key === "ArrowUp") {
    event.preventDefault();
    if (activeIndex.value > 0) {
      activeIndex.value--;
    }
  } else if (event.key === "ArrowDown") {
    event.preventDefault();
    if (activeIndex.value < items.value.length - 1) {
      activeIndex.value++;
    }
  } else if (event.key === "Escape") {
    showItems.value = false;
    activeIndex.value = -1;
  } else if (event.key === "Enter" && activeIndex.value >= 0) {
    if (filteredList.value?.length > 0) {
      selectItem(filteredList.value[activeIndex.value]);
    } else {
      selectItem(items.value[activeIndex.value]);
    }
  } else if (event.key === "Tab") {
    showItems.value = false;
    activeIndex.value = -1;
  }
};

let searchText = ref("");
const filteredList = ref();
const regex = /^[a-zA-Z]+$/;

const filterItems = async (e) => {
  if (e === form.value?.label && e !== "") {
    return;
  }

  if (props.searchable && e) {
    emits("search", e.toLowerCase());
    return;
  }

  if (e === "") {
    filteredList.value = [];
    items.value = itemClone.value;
    form.value.label = "";

    if (props.searchable) {
      emits("search", "");
    }

    emits("update:modelValue", undefined);
    showItems.value = true;
  }

  if (e === form.value?.label) {
    return;
  }

  if (e && !props.searchable) {
    searchText.value = e.toLowerCase();

    if (searchText) {
      filteredList.value = items.value.filter((item) =>
        item.label.toLowerCase().includes(searchText.value),
      );

      const exactMatch = items.value.find(
        (item) => item.label.toLowerCase() === searchText.value,
      );

      if (exactMatch) {
        selectItem(exactMatch);
      }
    }
  }
};

const validateInput = (inputValue) => {
  if (inputValue) {
    const isValid = items.value.some((item) => item.label === inputValue);
    if (!isValid) {
      validationMessage.value = "Please select an item.";
      emits("validation", false);
    } else {
      validationMessage.value = "";
      emits("validation", true);
    }
  } else {
    validationMessage.value = "";
    emits("validation", true);
  }
};

const getNextPage = (val) => {
  if (val) {
    emits("nextPage", true);
  }
};

const outSideDropdown = () => {
  showItems.value = false;
  activeIndex.value = -1;
};

const isPastDate = (date: any) => {
  const today = new Date();
  const given = new Date(date);

  // Clear the time part for accurate comparison
  today.setHours(0, 0, 0, 0);
  given.setHours(0, 0, 0, 0);

  // Check if the given date is in the past
  return given < today;
};
const isNotary = props.label === "Select Notary *";
</script>

<template>
  <div
    class="relative w-full min-w-fit"
    v-on-click-outside="outSideDropdown"
    :class="wrapperClass"
  >
    <FormKit
      v-bind="$attrs"
      type="text"
      ref="select"
      :model-value="form?.label"
      :label="label ? label : 'Select'"
      wrapper-class="relative"
      input-class="dark:!text-white"
      :disabled="props.disabled"
      @input="filterItems($event)"
      @keydown="props.preventKeydown ? $event.preventDefault() : undefined"
      autocomplete="off"
      :validation-message="validationMessage ?? 'Please select an item'"
    >
      {{ console.log(props.disabled, "fff") }}
      <template #suffix>
        <Icon
          name="heroicons:chevron-down"
          :class="[
            'absolute text-xl cursor-pointer right-3',
            props.disabled ? 'text-gray-400 cursor-not-allowed' : '',
          ]"
          @click="
            !props.disabled && (showItems = !showItems),
              !props.disabled && selectInput?.focus()
          "
        />
      </template>
    </FormKit>
    <div
      class="z-[60] bg-white dark:bg-midnight w-full overflow-y-auto max-h-[300px] absolute rounded border border-[#E4E7EB] dark:border-[#374151] -mt-2 shadow-input"
      v-if="showItems"
      ref="list"
    >
      <ol v-if="items?.length || slots.other" class="min-w-full">
        <template v-for="(item, index) in filteredList" :key="index">
          <li
            v-if="filteredList?.length"
            :class="{
              'bg-gray-200 dark:bg-darkPrimaryBG':
                index === activeIndex &&
                !(
                  isNotary &&
                  isPastDate(item?.data?.notary_detail?.comm_exp_date)
                ),
              'cursor-not-allowed text-gray-400':
                isNotary &&
                isPastDate(item?.data?.notary_detail?.comm_exp_date),
              'cursor-pointer hover:bg-gray-200 dark:hover:bg-darkPrimaryBG': !(
                isNotary && isPastDate(item?.data?.notary_detail?.comm_exp_date)
              ),
            }"
            @mouseenter="
              !(
                isNotary && isPastDate(item?.data?.notary_detail?.comm_exp_date)
              ) && setActiveIndex(index)
            "
            @click="
              !(
                isNotary && isPastDate(item?.data?.notary_detail?.comm_exp_date)
              ) && selectItem(item)
            "
          >
            {{ item?.label }}
            <span
              v-if="
                isNotary && isPastDate(item?.data?.notary_detail?.comm_exp_date)
              "
              class="text-red-500"
            >
              - Commission date is expired
            </span>
          </li>
        </template>
        <template v-for="(item, index) in items" :key="index">
          <li
            v-if="!filteredList?.length"
            class="z-30 block min-w-full p-2"
            :class="{
              'bg-gray-200 dark:bg-darkPrimaryBG':
                index === activeIndex &&
                !(
                  isNotary &&
                  isPastDate(item?.data?.notary_detail?.comm_exp_date)
                ),
              'cursor-not-allowed text-gray-400':
                isNotary &&
                isPastDate(item?.data?.notary_detail?.comm_exp_date),
              'cursor-pointer hover:bg-gray-200 dark:hover:bg-darkPrimaryBG': !(
                isNotary && isPastDate(item?.data?.notary_detail?.comm_exp_date)
              ),
            }"
            @mouseenter="
              !(
                isNotary && isPastDate(item?.data?.notary_detail?.comm_exp_date)
              ) && setActiveIndex(index)
            "
            @click="
              !(
                isNotary && isPastDate(item?.data?.notary_detail?.comm_exp_date)
              ) && selectItem(item)
            "
          >
            {{ item?.label }}
            <span
              v-if="
                isNotary && isPastDate(item?.data?.notary_detail?.comm_exp_date)
              "
              class="text-red-500"
            >
              - Commission date is expired
            </span>
          </li>
        </template>
        <!-- <template v-for="(item, index) in filteredList" :key="index">
          {{ console.log(filteredList) }}
          <li
            v-if="filteredList?.length"
            class="z-30 block min-w-full p-2 cursor-pointer pointer-events-auto hover:bg-gray-200 dark:hover:bg-darlPrimaryBG"
            :class="{
              'bg-gray-200 dark:bg-darkPrimaryBG': index === activeIndex,
            }"
            @mouseenter="setActiveIndex(index)"
            @click="selectItem(item)"
          >
            {{ item?.label }}
          </li>
        </template>
        <template v-for="(item, index) in items" :key="index">
          {{ console.log(item?.data?.notary_detail?.comm_exp_date,isPastDate(item?.data?.notary_detail?.comm_exp_date)) }}
          <li
            v-if="!filteredList?.length"
            class="z-30 block min-w-full p-2 cursor-pointer pointer-events-auto hover:bg-gray-200 dark:hover:bg-darlPrimaryBG"
            :class="{
              'bg-gray-200 dark:bg-darkPrimaryBG': index === activeIndex,
            }"
            @mouseenter="setActiveIndex(index)"
            @click="selectItem(item)"
          >
            {{ item?.label }}
          </li>
        </template> -->
        <li
          v-if="slots.other"
          @click="emits('add', true), outSideDropdown()"
          @mouseenter="setActiveIndex(items?.length + 1)"
          class="z-30 block min-w-full p-2 cursor-pointer pointer-events-auto hover:bg-gray-200 dark:hover:bg-darkPrimaryBG"
          :class="{
            'bg-gray-200 dark:bg-darkPrimaryBG':
              items?.length + 1 === activeIndex,
          }"
        >
          <slot name="other" />
        </li>
      </ol>
      <p
        v-if="!items?.length && !loading && !slots.other"
        class="z-30 min-w-full p-2 text-center cursor-pointer pointer-events-auto"
      >
        {{ emptyMessage ? emptyMessage : "Sorry, No results found" }}
      </p>
      <p
        class="z-30 min-w-full p-2 text-center cursor-pointer pointer-events-auto"
        v-if="fetchingNextPage || loading"
      >
        Loading...
      </p>
      <div
        v-element-visibility="getNextPage"
        v-if="hasNextPage"
        class="h-1"
      ></div>
    </div>
    <div v-if="validationMessage" class="text-red-500 mt-2">
      {{ validationMessage }}
    </div>
  </div>
</template>
<style scoped>
.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
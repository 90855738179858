const _transparent = "transparent"
const _current = "currentColor"
const _midnight = "#101827"
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _ngreen = "#06C972"
const _primaryHover = "#1F242B"
const _secondaryHover = "#0D9E5B"
const _darkFormBG = "#181F28"
const _darkFormBorder = "#9A9A9C"
const _primaryBG = "#F8F8F8"
const _secondaryBG = "#ffffff"
const _darkPrimaryBG = "#090e17"
const _darkCard = "#161B22"
const _darkSecondaryBg = "#2C2E35"
const _info = "#2F80ED"
const _success = "#008F0B"
const _warning = "#F5B900"
const _error = "#E10000"
const _offWhite = "#E5E5E5"
const _tableLight = "#F3F3F3"
const _tableDark = "#161B22"
const _black = "#000"
const _white = "#fff"
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const _green = {"50":"#ecfdf5","100":"#d1fae5","200":"#a7f3d0","300":"#6ee7b7","400":"#34d399","500":"#10b981","600":"#059669","700":"#047857","800":"#065f46","900":"#064e3b","950":"#022c22"}
const _purple = {"50":"#f5f3ff","100":"#ede9fe","200":"#ddd6fe","300":"#c4b5fd","400":"#a78bfa","500":"#8b5cf6","600":"#7c3aed","700":"#6d28d9","800":"#5b21b6","900":"#4c1d95","950":"#2e1065"}
const _yellow = {"50":"#fffbeb","100":"#fef3c7","200":"#fde68a","300":"#fcd34d","400":"#fbbf24","500":"#f59e0b","600":"#d97706","700":"#b45309","800":"#92400e","900":"#78350f","950":"#451a03"}
const _pink = {"50":"#fdf4ff","100":"#fae8ff","200":"#f5d0fe","300":"#f0abfc","400":"#e879f9","500":"#d946ef","600":"#c026d3","700":"#a21caf","800":"#86198f","900":"#701a75","950":"#4a044e"}
const _red = {"50":"#fef2f2","100":"#fee2e2","200":"#fecaca","300":"#fca5a5","400":"#f87171","500":"#ef4444","600":"#dc2626","700":"#b91c1c","800":"#991b1b","900":"#7f1d1d","950":"#450a0a"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "transparent": _transparent, "current": _current, "midnight": _midnight, "primary": _primary, "ngreen": _ngreen, "primaryHover": _primaryHover, "secondaryHover": _secondaryHover, "darkFormBG": _darkFormBG, "darkFormBorder": _darkFormBorder, "primaryBG": _primaryBG, "secondaryBG": _secondaryBG, "darkPrimaryBG": _darkPrimaryBG, "darkCard": _darkCard, "darkSecondaryBg": _darkSecondaryBg, "info": _info, "success": _success, "warning": _warning, "error": _error, "offWhite": _offWhite, "tableLight": _tableLight, "tableDark": _tableDark, "black": _black, "white": _white, "gray": _gray, "green": _green, "purple": _purple, "yellow": _yellow, "pink": _pink, "red": _red, "cool": _cool,  }
export { config as default, _transparent, _current, _midnight, _primary, _ngreen, _primaryHover, _secondaryHover, _darkFormBG, _darkFormBorder, _primaryBG, _secondaryBG, _darkPrimaryBG, _darkCard, _darkSecondaryBg, _info, _success, _warning, _error, _offWhite, _tableLight, _tableDark, _black, _white, _gray, _green, _purple, _yellow, _pink, _red, _cool }
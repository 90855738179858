import { FormKitTypeDefinition } from "@formkit/core";
import {
  actions,
  disablesChildren,
  formInput,
  forms,
  message,
  messages,
  submitInput,
} from "@formkit/inputs";

/**
 * Input definition for a form.
 * @public
 */
export const form: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: formInput(
    messages(message("$message.value")),
    "$slots.default",
    actions(submitInput()),
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: "group",
  /**
   * An array of extra props to accept for this input.
   */
  props: [
    "actions",
    "submit",
    "submitLabel",
    "submitAttrs",
    "submitBehavior",
    "incompleteMessage",
  ],
  /**
   * Additional features that should be added to your input
   */
  features: [forms, disablesChildren],
};

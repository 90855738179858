<script setup lang="ts">
const messages = useMessage();
</script>

<template>
  <div class="fixed z-[99999] bottom-5 right-5 min-w-[350px] max-w-[350px]">
    <TransitionGroup
      name="fade"
      tag="div"
      class="relative min-w-[350px] max-w-[350px]"
    >
      <div
        v-for="item in messages.toasts"
        :key="item"
        class="min-w-[350px] max-w-[350px]"
      >
        <div
          class="py-6 px-4 mb-4 min-w-full border-darkFormBorder border-opacity-25 rounded shadow-ui bg-white/80 backdrop-blur-md dark:backdrop-blur-sm dark:bg-[#171F28]/80 border-[1px] dark:text-white"
          :class="{
            'border-success border-opacity-80 border-l-4':
              item.type === 'success',
            'border-error border-opacity-80 border-l-4': item.type === 'error',
            'border-warning border-opacity-80 border-l-4':
              item.type === 'warning',
          }"
        >
          <div class="relative min-w-full">
            <div
              class="absolute cursor-pointer -top-5 -right-2"
              @click="messages.remove(item.id)"
            >
              <Icon name="heroicons:x-mark-20-solid" class="text-xl" />
            </div>
          </div>
          <div class="flex items-center min-w-full">
            <div class="flex items-start mr-3" v-if="item.icon">
              <Icon
                :name="item.icon"
                class="text-2xl"
                :class="{
                  'text-success': item.type === 'success',
                  'text-error': item.type === 'error',
                  'text-warning': item.type === 'warning',
                }"
              />
            </div>
            <div class="block">
              <div class="text-sm font-medium capitalize">
                {{ item.title }}
              </div>
              <div class="text-xs font-medium">{{ item.message }}</div>
            </div>
          </div>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity, transform;
  @apply duration-300 ease-in-out;
}
.fade-move {
  @apply transition-all duration-500 ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  transform: translate(350px, 0);
  @apply opacity-0 blur-sm -z-10;
}
.fade-leave-active {
  @apply absolute;
}
</style>

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/svg+xml","href":"/favicon.svg"}],"style":[],"script":[{"type":"text/javascript","children":"if(!window.$sleek){window.$sleek=[];window.SLEEK_PRODUCT_ID=530366359;(function(){d=document;s=d.createElement(\"script\");s.src=\"https://client.sleekplan.com/sdk/e.js\";s.async=1;d.getElementsByTagName(\"head\")[0].appendChild(s);})();}"}],"noscript":[],"htmlAttrs":{"class":"h-full"},"bodyAttrs":{"class":"h-full"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false